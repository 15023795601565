.ce_form {
	@include responsify($spacing-lg, padding-top);
	@include responsify($spacing-xxl, padding-bottom);
	min-height: 50vh;
	background: $color-white;

	.form-headline {
		@include container('narrow');
		@include responsify($spacing-md, margin-bottom);
		@include fluidsize($font-sizes-lg);
	}

	form {
		@include container();
		overflow: visible;

		@include media('>=md') {
			@include container('narrow');
		}
	}

	.widget {
		@include responsify($spacing-sm, margin-bottom);
		clear: both;

		&.error {
			margin-bottom: 4rem;

			@include media('>=md') {
				@include responsify($spacing-xxl, margin-bottom);
			}
		}
	}

	.widget-text {
		$field: &;
		@include responsify($field-fontsize);
		@include responsify($field-line-height, 'line-height');
		position: relative;

		&:hover {
			input,
			textarea {
				background: transparent;
			}

			label {
				color: $field-label-color-active;
			}
		}

		&:focus-within input,
		&:focus-within textarea,
		input:focus,
		input:not(:placeholder-shown),
		textarea:focus,
		textarea:not(:placeholder-shown) {
			color: $field-color-active;

			+ label {
				top: 0;
				padding-top: (0.25 * $field-padding);
				color: $field-label-color-active;
				font-size: 0.70rem;
			}
		}

		&.error {
			&:focus-within input,
			&:focus-within textarea,
			input:focus,
			input:not(:placeholder-shown),
			textarea:focus,
			textarea:not(:placeholder-shown) {
				border-bottom-color: $color-red;
			}
		}

		& #{$field}--error {
			input,
			textarea {
				color: $field-linecolorinvalid;
				box-shadow: inset 0 -0.125rem 0 0 $field-linecolorinvalid;
			}
		}

		input {
			$input: &;
			position: relative;
			width: 100%;
			padding: (2 * $field-padding) $field-padding 0.05rem; // use var
			background: $field-containerbgcolor; // use var
			border-bottom: solid 2px $color-black;
			border-radius: $field-border-radius;
			color: $field-color;
			font: inherit;
			font-weight: $font-weight-medium;
			line-height: inherit;
			appearance: none;
			transition: background-color 0.25s ease, box-shadow 0.25s ease;

			@at-root {
				@-webkit-keyframes autofill {
					to {
						color: $field-color;
						background: transparent;
					}
				}
			}

			&:-webkit-autofill {
				-webkit-animation-name: autofill;
				-webkit-animation-fill-mode: both;
			}
		}

		label {
			@include responsify($font-sizes-nm);
			position: absolute;
			top: 5px;
			left: 0;
			padding: (1.5 * $field-padding) $field-padding 0;
			line-height: inherit;
			transition: 0.15s ease;
			color: $color-black;
			pointer-events: none;
			user-select: none;
			will-change: padding-top, font-size;
			font: inherit;
			font-weight: $font-weight-medium;
			cursor: text;
			z-index: 10;
		}

		&.short-field {
			width: 100%;

			@include media('>=#{$desktop-breakpoint}') {
				width: 48%;
			}
		}
	}

	.widget-textarea {
		@extend .textarea__container;

		.textarea {
			@extend .textarea__textarea;
			@include responsify($field-fontsize);
			padding: (2 * $field-padding) $field-padding 0; // use var
			margin-bottom: 0;
			border-bottom: 2px solid $color-black;
			transition: background 0.3s;
			overflow: hidden;
			color: $color-black;
			font-weight: $font-weight-medium;

			&:focus,
			&:not(:placeholder-shown) {
				border: none;
				border-bottom: 2px solid $color-black;

				& ~ label {
					@include responsify($spacing-xs, 'padding');
					@include responsify($spacing-no, 'padding-left');
					top: -0.125rem;
					bottom: auto;
					transition: font-size 0.2s $ease-out-quad, top 0.2s $ease-out-quad;
					font-size: 0.75rem;
					color: $field-label-color-active;

					@include media('<md') {
						top: 0.3rem;
					}
				}
			}
		}

		label {
			@extend .textarea__label;
			@include responsify($spacing-no, 'padding-left');
			transition: 0.15s ease;
			font-weight: $font-weight-medium;
			color: $color-black;
		}

		&:hover {
			input,
			textarea {
				background: transparent;
			}

			label {
				color: $field-label-color-active;
			}
		}

		&:focus-within textarea,
		textarea:focus,
		textarea:not(:placeholder-shown) {
			background: transparent;
			border: solid 2px $field-label-color-active;

			label {
				top: -6px;
			}
		}

		&.short-field {
			width: 100%;

			@include media('>=#{$desktop-breakpoint}') {
				width: 48%;
			}
		}

		p.error {
			bottom: -20px;
		}
	}

	.widget-select {
		select {
			@include responsify($spacing-xs, margin-top);
			height: 59px;
			font-weight: $font-weight-medium;
			box-sizing: border-box;
			color: $color-black;

			@include media('>=md') {
				height: 64px;
			}

			option {
				font-weight: $font-weight-medium;
				color: $color-black;
			}
		}

		label {
			position: static;
			font-weight: $font-weight-medium;
		}

		&.short-field {
			width: 100%;

			@include media('>=#{$desktop-breakpoint}') {
				width: 48%;
			}
		}
	}

	.widget-radio {
		fieldset {
			@include radio-container($mode: 'before', $border-radius: 100%);

			input:focus + label:before,
			input:checked + label:before {
				border-color: transparent;
			}

			input {
				display: none;
			}

			> span {
				display: block;
				margin: 0.625rem 0;
			}

			&.inline-radio-buttons {
				display: flex;

				span {
					margin-right: 0.8rem;
				}
			}
		}

		label {
			@include radio-label();
		}
	}

	.widget-submit {
		@include responsify($spacing-lg, margin-top);
		@include responsify($spacing-no, margin-bottom);

		button {
			@extend .btn;
			@extend .btn--primary;
		}
	}

	p.error {
		@include responsify($font-sizes-xs);
		position: absolute;
		bottom: -40px;
		padding: 0 0.75rem;
		margin-bottom: 0;
		color: $color-red;

		@include media('>=md') {
			bottom: -50px;
		}
	}
}

.position-field-left {
	float: left;
	width: 48%;
	margin-right: 4%;
	clear: left !important;

	&.short-field {
		width: 48%;
		float: right !important;

		@include media('>=sm') {
			width: 30%;
		}
	}
}

.position-field-right {
	float: right;
	width: 48%;
	clear: right !important;

	&.short-field {
		width: 48%;
		float: left !important;

		@include media('>=sm') {
			width: 30%;
		}
	}
}
