.preview-carousel {
	$process: &;

	&__glide {
		@include container();
		@include responsify($side-margins, padding-left);
		@include responsify($side-margins, padding-right);
		width: 100vw;
		overflow: hidden;

		@include media('>=1800px') {
			@include responsify($spacing-no, padding);
		}
	}

	&__single {
		@include responsify($spacing-xxs, padding-right);
		height: 100%;
		opacity: 1;
		color: $color-white;
		cursor: pointer;

		&.glide__slide--active {
			color: $color-white;
		}

		&:hover {
			#{$process}__image {
				transform: scale(1.04) translateY(-5px);
			}
		}
	}

	&__head {
		display: flex;
		align-items: flex-end;
		justify-content: space-between;

		@include media('>=lg') {
			padding-top: 1rem;
			padding-left: 0.2rem;
		}

		.glide__arrows {
			@include responsify($spacing-md, margin-bottom);
			display: flex;
			justify-content: flex-start;
			align-items: center;
			flex: 1;
			position: absolute;
			top: 50%;
			left: 0;
			width: 100%;
			transform: translateY(-50%);
			z-index: 9;

			@include media('>=md') {
				@include responsify($spacing-sm, margin-bottom);
			}

			.glide__arrow {
				position: absolute;
				display: flex;
				align-items: center;
				justify-content: center;
				width: 49.7px;
				height: 49.7px;
				padding: 0;
				background: rgba(85, 120, 115, 0.7);
				transition: 0.3s $ease-out-quad;
				border-radius: 100px;
				border: none;
				cursor: pointer;
				transform: scale(0.6);

				@include media('>=md') {
					transform: scale(1);
				}

				&:hover {
					transform: scale(0.7);

					@include media('>=md') {
						transform: scale(1.05);
					}
				}

				&--left {
					@include responsify($side-margins, left);
					padding-right: 0;
					margin-right: 0.5rem;
				}

				&--right {
					@include responsify($side-margins, right);
					padding-right: 0;
					transform: rotate(180deg) scale(0.6);

					@include media('>=md') {
						transform: rotate(180deg) scale(1);
					}

					&:hover {
						transform: rotate(180deg) scale(0.7);

						@include media('>=md') {
							transform: rotate(180deg) scale(1.05);
						}
					}
				}

				svg {
					stroke: $text-color;
				}
			}
		}
	}

	&__content {
		position: relative;
		display: flex;
		flex-flow: column;
		align-items: stretch;
		height: 0;
		padding-bottom: 62.5%;
		background: $color-white;
		box-sizing: border-box;
		overflow: hidden;

		@include media('>=md') {
			@include responsify($spacing-xxs, margin);
		}

		&:before {
			content: '';
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 10%, rgba(0, 0, 0, 0.5) 70%);
			transition: 0.3s $ease-out-quad;
			z-index: 90;
		}
	}

	&__image {
		position: absolute;
		inset: 0;
		height: 100%;
		width: 100%;
		transition: 0.3s $ease-out-quad;
		object-fit: cover;
	}

	&__description {
		@include responsify($font-sizes-md-plus);
		@include responsify($spacing-nm, padding-right);
		@include responsify($spacing-nm, padding-left);
		line-height: 1.2;
		position: absolute;
		left: 0;
		bottom: 2rem;
		font-weight: $font-weight-bold;
		z-index: 91;
	}

	.glide__slides {
		display: flex;
	}
}
