.icon-grid {
	@include container('wider');

	&__title {
		@include responsify($spacing-xxl, margin-bottom);
		@include fluidsize($font-sizes-xl);
		font-weight: $font-weight-bold;
		text-align: center;
	}

	&__collection {
		display: flex;
		flex-flow: row wrap;
		align-items: center;
		justify-content: center;

		@include media('>=#{$desktop-breakpoint}') {
			flex-flow: row wrap;
		}
	}

	&__single {
		flex: 0 0 40%;
		display: flex;
		justify-content: center;
		margin: 2rem 0;

		@include media('>=#{$desktop-breakpoint}') {
			flex: 0 0 25%;
			margin: 0;
		}
	}

	&__logo {
		display: flex;
		align-items: center;
		justify-content: center;
		max-width: 150px;
		width: 100%;
		height: auto;
		object-fit: cover;
		overflow: hidden;

		@include media('>=md') {
			max-width: 200px;
		}
	}
}
