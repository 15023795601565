.btn {
	@include button();
	@include responsify($line-height-nm, line-height);

	&:hover {
		background: lighten($color-dark-teal, 5%);
	}

	&--primary {
		color: $button-color;
		background: $button-primary-bg;
	}
}
