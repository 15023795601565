.video {
	@include responsify($spacing-xxl, padding-top);
	@include responsify($spacing-xxl, padding-bottom);
	@include container();

	@include media('>=lg') {
		@include container('wider');
	}

	&__wrapper {
		position: relative;
		height: 0;
		margin: 2rem auto;

		&,
		&--169 {
			padding-bottom: $ratio-16-9;
		}

		&--1610 {
			padding-bottom: $ratio-16-10;
		}

		&--219 {
			padding-bottom: $ratio-21-9;
		}

		&--43 {
			padding-bottom: $ratio-4-3;
		}

		&--32 {
			padding-bottom: $ratio-3-2;
		}

		img {
			@include full-height-media();
			pointer-events: all;
		}

		iframe {
			position: absolute;
			top: 0;
			bottom: 0;
			right: 0;
			left: 0;
			max-width: 100%;
			width: 100%;
			height: 100%;
			margin: 0 auto;
		}

		video {
			width: 100%;
		}

		.teaser-grid__single & {
			margin: 0;
		}

		.teaser-grid--flex-text-left &,
		.teaser-grid--flex-text-right & {
			margin: 0;
		}
	}

	&__poster {
		cursor: pointer;

		&:hover {
			&:before {
				opacity: 0.4;
			}

			svg {
				transform: translate(-50%, -50%) scale(1.125);
			}
		}

		&:before {
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			background: $color-black;
			opacity: 0.2;
			transition: 0.3s $ease-out-quad;
			z-index: 10;
		}

		svg {
			position: absolute;
			top: 50%;
			left: 50%;
			display: block;
			width: 3em;
			height: 3em;
			fill: $color-white;
			transition: 0.3s $ease-out-quad;
			transform: translate(-50%, -50%);
			overflow: visible;
			z-index: 11;

			@include media('>=md') {
				width: 4em;
				height: 4em;
			}
		}
	}
}
