.top-section {
	position: relative;
	height: 100%;
	overflow: hidden;

	&__container {
		position: relative;
		// must match section--hero mobile height
		height: $section-min-mobile-height;

		&:before {
			content: '';
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 10%, rgba(0, 0, 0, 0.5) 70%);
			z-index: 90;
		}
	}

	&__image {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	&__title-container {
		@include container('wider');
		@include responsify($spacing-lg, padding);
		@include responsify($spacing-xxl, padding-bottom);
		position: absolute;
		left: 0;
		right: 0;
		bottom: 3.25rem;
		z-index: 91;
	}

	&__title {
		@include responsify($font-sizes-xl);
		@include responsify($line-height-sm, line-height);
		width: 100%;
		margin-bottom: 0;
		color: $color-white;
		text-align: left;
		font-weight: $font-weight-bold;
		z-index: 99;

		@include media('>=#{$desktop-breakpoint}') {
			width: 70%;
		}
	}
}

.navigate-down {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	text-align: center;
	z-index: 99;

	.navigation-open & {
		z-index: 98;
	}

	svg {
		width: 40px;
		height: 40px;
		cursor: pointer;

		@include media('>=ms') {
			width: 80px;
			height: 80px;
		}
	}
}
