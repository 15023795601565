@mixin container($size: 'normal') {
	margin-left: auto;
	margin-right: auto;
	@include responsify($side-margins, "padding-left");
	@include responsify($side-margins, "padding-right");
	width: 100%;

	@each $sizeitem in $container-sizes {
		@if $sizeitem == $size {
			$index: index($container-sizes, $size);
			max-width: nth($container-max-widths, $index); // set max-width to according list value
		}
	}
}
