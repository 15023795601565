.spacing {
	&--normal {
		@include responsify($spacing-lg, 'margin-top');
		@include responsify($spacing-lg, 'margin-bottom');
	}

	&--top {
		@include responsify($spacing-lg, 'margin-top');
	}

	&--bottom {
		@include responsify($spacing-lg, 'margin-bottom');
	}

	&--extended {
		@include responsify($spacing-xxxl, 'margin-top');
		@include responsify($spacing-xxxl, 'margin-bottom');

		&-top {
			@include responsify($spacing-xxxl, 'margin-top');
			@include responsify($spacing-no, 'margin-bottom');
		}

		&-bottom {
			@include responsify($spacing-no, 'margin-top');
			@include responsify($spacing-xxxl, 'margin-bottom');
		}
	}

	&-pd {
		&--normal {
			@include responsify($spacing-lg, 'padding-top');
			@include responsify($spacing-lg, 'padding-bottom');
		}

		&--top {
			@include responsify($spacing-lg, 'padding-top');
		}

		&--bottom {
			@include responsify($spacing-lg, 'padding-bottom');
		}

		&--extended {
			@include responsify($spacing-xxxl, 'padding-top');
			@include responsify($spacing-xxxl, 'padding-bottom');

			&-top {
				@include responsify($spacing-xxxl, 'padding-top');
				@include responsify($spacing-no, 'padding-bottom');
			}

			&-bottom {
				@include responsify($spacing-no, 'padding-top');
				@include responsify($spacing-xxxl, 'padding-bottom');
			}
		}
	}
}
