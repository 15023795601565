/**
 * Spacing helpers
 */

.spacing {
  .spacing__top--1,
  .spacing__top--xs-1 {
    padding-top: 1rem;
  }

  .spacing__top--2,
  .spacing__top--xs-2 {
    padding-top: 2rem;
  }

  .spacing__top--3,
  .spacing__top--xs-3 {
    padding-top: 3rem;
  }

  .spacing__top--4,
  .spacing__top--xs-4 {
    padding-top: 4rem;
  }

  .spacing__top--5,
  .spacing__top--xs-5 {
    padding-top: 5rem;
  }

  .spacing__top--6,
  .spacing__top--xs-6 {
    padding-top: 6rem;
  }

  .spacing__bottom--1,
  .spacing__bottom--xs-1 {
    padding-bottom: 1rem;
  }

  .spacing__bottom--2,
  .spacing__bottom--xs-2 {
    padding-bottom: 2rem;
  }

  .spacing__bottom--3,
  .spacing__bottom--xs-3 {
    padding-bottom: 3rem;
  }

  .spacing__bottom--4,
  .spacing__bottom--xs-4 {
    padding-bottom: 4rem;
  }

  .spacing__bottom--5,
  .spacing__bottom--xs-5 {
    padding-bottom: 5rem;
  }

  .spacing__bottom--6,
  .spacing__bottom--xs-6 {
    padding-bottom: 6rem;
  }

  @each $breakpoint, $screensize in $breakpoints {
    @if ($breakpoint != 'xxs' and $breakpoint != 'xs') {
      @include media('>=#{$breakpoint}') {
        @for $i from 1 to 6 {
          &__top--#{$breakpoint}-#{$i} {
            padding-top: ($i * 1rem);
          }

          &__bottom--#{$breakpoint}-#{$i} {
            padding-bottom: ($i * 1rem);
          }
        }
      }
    }
  }
}
