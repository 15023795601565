@mixin reverseListReset() {
	ul,
	ol {
		padding-left: 1.25rem;
		list-style-type: disc;

		@include media('>=md') {
			padding-left: 1.5rem;
		}

		li {
			margin-bottom: 0.5em;

			&:last-of-type {
				margin-bottom: 0;
			}
		}
	}
}

