.lead-text {
	position: relative;
	height: 40vh;

	@include media('>=lg') {
		height: 60vh;
	}

	.section--skewed & {
		@include media('<lg') {
			height: auto;
		}
	}

	&__content {
		@include container('narrow-plus');
		position: absolute;
		top: 50%;
		left: 0;
		right: 0;
		color: $color-white;
		text-align: center;
		transform: translateY(-50%);

		&--black-font {
			color: $color-black;
		}

		.section--skewed & {
			@include media('<lg') {
				@include responsify($spacing-lg, padding-top);
				@include responsify($spacing-lg, padding-bottom);
				position: static;
				top: auto;
				transform: translateY(0);
			}
		}
	}

	&__overhead-title {
		@include responsify($spacing-xs, margin-bottom);
		@include fluidsize($font-sizes-md);
		@include responsify($font-sizes-md);
	}

	&__text {
		@include fluidsize($font-sizes-lg);
		@include responsify($line-height-md, line-height);
		font-weight: $font-weight-semi-bold;

		&--large {
			@include fluidsize($font-sizes-xl);
			font-weight: $font-weight-bold;
		}
	}
}

.headline-container {
	@include container('narrow-plus');
	//padding: 3rem 0;

	.headline {
		text-align: center;

		&--large {
			@include responsify($font-sizes-xl);
		}
	}
}
