body {
	display: flex;
	flex-flow: column;

	.is-ie & {
		overflow-x: hidden;
	}
}

.content {
	@include responsify($side-margins, 'padding-top');
	@include responsify($spacing-no, 'padding-bottom');
	flex: 1 1 auto;
}

.footer {
	flex: 0 0 auto;
}

.container {
	@include container('normal');

	&--slim {
		@include container('slim');
	}

	&--narrow {
		@include container('narrow');
	}

	&--narrow-plus {
		@include container('narrow-plus');
	}

	&--wider {
		@include container('wider');
	}

	&--fluid {
		@include container('fluid');
	}

	&,
	&--slim,
	&--narrow,
	&--fluid {
		.container {
			&,
			&--slim,
			&--narrow,
			&--fluid {
				@include responsify($spacing-no, 'padding-right');
				@include responsify($spacing-no, 'padding-left');
			}
		}
	}
}

.clearfix {
	&:after,
	&:before {
		content: '';
		display: table;
		clear: both;
	}
}

.margin-up-down {
	margin: 0;

	@for $h from 1 through 20 {
		&-#{$h} {
			margin: calc(#{$h}rem / 2) 0;

			@include media('>=#{$desktop-breakpoint}') {
				margin: #{$h}rem 0;
			}
		}
	}
}

.padding-up-down {
	padding: 0;

	@for $h from 1 through 20 {
		&-#{$h} {
			padding: calc(#{$h}rem / 2) 0;

			@include media('>=#{$desktop-breakpoint}') {
				padding: #{$h}rem 0;
			}
		}
	}
}

.padding-up {
	padding: 0;

	@for $h from 1 through 20 {
		&-#{$h} {
			padding-top: calc(#{$h}rem / 2);

			@include media('>=#{$desktop-breakpoint}') {
				padding-top: #{$h}rem;
			}
		}
	}
}

.padding-down {
	padding: 0;

	@for $h from 1 through 20 {
		&-#{$h} {
			padding-bottom: calc(#{$h}rem / 2);

			@include media('>=#{$desktop-breakpoint}') {
				padding-bottom: #{$h}rem;
			}
		}
	}
}
