// THIS FILE MAPS THE BASIC CLASSES AND SEMANTICS OF THIS BOILERPLATE TO THE PROJECTS CLASSES OR SEMANTICS - YOU HAVE TO ADJUST THIS FILE ACCORDING YOUR NEEDS
// debug

@-ms-viewport {
	width: device-width;
}

* {
	&,
	&:before,
	&:after {
		box-sizing: inherit;
	}
}

html {
	box-sizing: border-box;
	font-size: 16px;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html {
	min-height: 100%;
	//hack: prevents content overflow
	//display: flex;

	&.navigation-open {
		overflow: hidden;
	}
}

body {
	flex: 1;
	//hack: prevents content overflow
	min-height: 100vh;
}

// Basic FONT  usage
body,
input,
select,
td,
textarea,
th,
textarea,
input,
button {
	@include responsify($font-sizes-nm);
	@include responsify($line-height-lg, 'line-height');
	@include font-smoothing;
	font-family: $font-family-base;
	font-weight: $font-weight-normal;
}

body {
	position: relative;
	color: $text-color;
	background: $body-bg;
	-ms-overflow-style: scrollbar;
	-webkit-overflow-scrolling: touch;
}

.grainy-background {
	position: relative;

	&:before {
		content: '';
		position: absolute;
		top: 0;
		bottom: 0;
		display: block;
		width: 100%;
		height: 100%;
		background: url($background-noise);
		opacity: 0.5;
		z-index: -1;
		background-repeat: no-repeat;
		background-size: cover;
		background-attachment: fixed;
	}
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
	font-weight: bold;
	margin: 0 0 0.375em;

	.section & {
		margin: 0;
	}
}

h1, .h1 {
	@include responsify($font-sizes-lg);
	@include responsify($line-height-sm, line-height);
}

.h1--fluid {
	@include fluidsize($font-sizes-lg);
}

h2, .h2 {
	@include responsify($font-sizes-md-plus);
}

.h2--fluid {
	@include fluidsize($font-sizes-md-plus);
}

h3, .h3 {
	@include responsify($font-sizes-md);
}

.h3--fluid {
	@include fluidsize($font-sizes-md);
}

h4, .h4 {
	@include responsify($font-sizes-md);
}

.h4--fluid {
	@include fluidsize($font-sizes-md);
}

h5, .h5 {
	@include responsify($font-sizes-md);
}

.h5--fluid {
	@include fluidsize($font-sizes-md);
}

h6, .h6 {
	@include responsify($font-sizes-md);
}

.h6--fluid {
	@include fluidsize($font-sizes-md);
}

a,
.link {
	color: $link-color;
	text-decoration: $link-decoration;
	transition: $link-transition;

	@include hover() {
		&:hover {
			color: $link-hover-color;
			text-decoration: $link-hover-decoration;
		}
	}
}

p {
	break-inside: avoid;
	margin-top: 0;
	margin-bottom: 1.5rem;
}

small {
	font-size: 0.75em;
}

hr {
	border: 0;
	border-bottom: 1px solid #ccc;
	margin: 1.5rem 0;
}

b,
strong,
.strong {
	font-weight: bold;
}

em {
	font-style: italic;
}

img {
	max-width: 100%;
	height: auto;
}

svg {
	max-width: 100%;
}

ol,
ul,
dl {
	margin-top: 0;
	margin-bottom: 1.5rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
	margin-bottom: 0;
}

blockquote {
	margin: 0 0 1.5rem;
}

[role="button"] {
	cursor: pointer;
}

a,
area,
button,
[role="button"],
input,
label,
select,
summary,
textarea {
	touch-action: manipulation;
}

textarea {
	resize: vertical;
}

[hidden] {
	display: none !important;
}

.invisible {
	display: none;
}

.startpage {
	.content {
		padding-top: 0;
	}
}

