.hamburger {
	$hamburger: &;
	//@include button();
	padding: 0;
	margin: 0;
	transition: 0.2s $ease-out-quad;
	color: $color-black;
	background: none;
	border: none;
	z-index: 10;

	@include media('>=#{$desktop-breakpoint}') {
		display: none;
	}

	&__inner {
		position: relative;
		display: flex;
		flex-flow: column;
		align-items: flex-end;
		justify-content: space-between;
		width: 2rem;
		height: 0.5rem;
	}

	&__line {
		//position: absolute;
		display: block;
		width: 100%;
		height: 2px;
		background: currentColor;
		border-radius: 100px;
		transition: 0.2s $ease-out-quad;

		&:nth-child(1) {
			//top: 40%;
			right: 0;
			transform: translate3d(0, -40%, 0);
		}

		&:nth-child(2) {
			//top: calc(40% + (0.4) * 1rem);
			right: 0;
			width: 75%;
			transform: translate3d(0, -40%, 0);
		}

		.navigation-open & {
			&:nth-child(1) {
				position: absolute;
				top: 50%;
				transform: translate3d(0, -50%, 0) rotate(-45deg);
			}

			&:nth-child(2) {
				position: absolute;
				top: 50%;
				width: 100%;
				transform: translate3d(0, -50%, 0) rotate(45deg);
			}
		}
	}
}


