// font-sizes: add as many as you want and also as many steps as you want. They are used step by step for responsive screens, beginning with the smallest resolution
// example: [default size and size for first entry in responsive breakpoints list], [size for second entry upwards], [size for third entry upwars] ,...
// example: [default size and size for first entry in responsive breakpoints list], [size for second entry upwards], [size for third entry upwars] ,...
$font-sizes-xxs: 						0.75rem, 0.75rem, 0.875rem, 0.875rem, 0.875rem, 0.875rem, 0.875rem;
$font-sizes-xs: 						0.75rem, 0.75rem, 0.875rem, 0.875rem, 0.875rem, 0.875rem, 0.875rem;
$font-sizes-sm: 						0.8rem, 0.875rem, 1rem, 1.1rem, 1.1rem, 1.1rem, 1.1rem;
$font-sizes-nm: 						0.9rem, 1.1rem, 1.125rem, 1.125rem, 1.125rem, 1.125rem, 1.125rem; //max - 18px
$font-sizes-md: 						1rem, 1.1rem, 1.2rem, 1.2rem, 1.25rem, 1.25rem, 1.375rem; //max - 22px
$font-sizes-md-plus: 				1.3rem, 1.4rem, 1.575rem, 1.875rem, 1.875rem, 1.875rem, 1.875rem; //max - 30px
$font-sizes-lg: 						1.5rem, 1.5rem, 1.9rem, 2.5rem, 2.5rem, 2.5rem, 2.5rem; //max - 40px
$font-sizes-lg-plus: 				1.562rem, 1.562rem, 2rem, 2.625rem, 2.625rem, 2.625rem, 2.625rem; //max - 42px
$font-sizes-xl: 						2.5rem, 3.5rem, 3.5rem, 3.5rem, 4rem, 4rem, 5rem; //max - 80px

// define line heights, to responsify them later
$line-height-nm: 1, 1, 1, 1;
$line-height-sm: 1.1125, 1.1125, 1.1125, 1.1125;
$line-height-md: 1.2, 1.2, 1.2;
$line-height-lg: 1.2, 1.35, 1.5, 1.5;
$line-height-xl: 1.68, 1.8, 2;

// font families
$font-family-base: 'Montserrat', sans-serif;

$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;
$font-weight-italic: 400;

$font-style-normal: normal;
$font-style-italic: italic;

// font-face-helper
@mixin font-face($name, $filename, $weight: null, $style: null, $exts: eot woff2 woff ttf svg) {
	$src: null;

	$extmods: (
		eot: "?",
		svg: "#" + str-replace($name, " ", "_")
	);

	$formats: (
		otf: "opentype",
		ttf: "truetype"
	);

	@each $ext in $exts {
		$extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
		$format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
		$src: append($src, url(quote($fonts-base-path + $filename + "." + $extmod)) format(quote($format)), comma);
	}

	@font-face {
		font-family: quote($name);
		font-style: $style;
		font-weight: $weight;
		src: $src;
	}
}

// ADD FONTS HERE with mixin
// example
// @include font-face('proxima-nova-web', 'proxima-nova-light', normal, null, woff2 woff);
// @include font-face('proxima-nova-web', 'proxima-nova-bold', bold, null, woff2 woff);
