.footer {
	@include responsify($spacing-lg, 'padding-top');
	position: relative;
	background: $color-grey-ultralight;

	&__content {
		@include container('wider');
		@include responsify($spacing-xl, 'padding-bottom');
		@include fluidsize($font-sizes-nm);
		display: flex;
		flex-flow: column;

		@include media('>=#{$desktop-breakpoint}') {
			@include responsify($spacing-md, 'padding-bottom');
			flex-flow: row nowrap;
			justify-content: space-between;
		}
	}

	&__contact-info {
		@include responsify($spacing-md, 'margin-top');
		display: flex;
		flex-flow: row nowrap;
		line-height: 1.5;
		align-items: center;
		font-weight: $font-weight-bold;

		@include media('<md') {
			order: 1;
		}

		@include media('>=md') {
			@include responsify($spacing-no, 'margin-top');
			@include responsify($spacing-no, 'margin-bottom');
			line-height: 1;
		}
	}

	&__links {
		@include media('<md') {
			display: flex;
			flex-flow: column;
			order: 0;

			a {
				@include responsify($spacing-sm, margin-bottom);
			}
		}

		a {
			@include responsify($spacing-md, margin-right);
			font-weight: $font-weight-bold;

			&:last-of-type {
				@include responsify($spacing-no, margin-right);
			}
		}
	}

	&__additional {
		@include container('fluid');
		@include responsify($spacing-xs, padding-bottom);
		@include fluidsize($font-sizes-xs);
		display: flex;
		justify-content: flex-end;
	}
}
