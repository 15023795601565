.double-sided {
	$double-sided: &;
	@include responsify($spacing-md, padding-top);
	@include responsify($spacing-md, padding-bottom);
	display: flex;
	flex-flow: column;

	@include media('>=md') {
		@include container();
		flex-flow: row wrap;
		justify-content: space-between;
	}

	&--reversed {
		#{$double-sided}__side {
			@include media('<md') {
				order: 0;
			}

			&--w-text {
				@include media('<md') {
					order: 1;
				}

				@include media('>=md') {
					@include responsify($spacing-lg, padding-left);
					padding-right: 0;
				}
			}
		}
	}

	&--text-cols {
		#{$double-sided}__side--w-text {
			padding-top: 0;

			@include media('>=md') {
				padding-bottom: 0;
			}

			&:last-of-type {
				@include media('<md') {
					padding-top: 0;
					padding-bottom: 0;
				}

				@include media('>=md') {
					padding-right: 0;
				}

				#{$double-sided}__content-text {
					@include media('<md') {
						padding-top: 0;
					}
				}
			}
		}
	}

	&__top-title {
		flex: 0 0 100%;

		@include media('<md') {
			@include responsify($spacing-lg, padding-top);
		}
	}

	&__side {
		flex: 1;

		&--w-text {
			@include responsify($spacing-xl, padding);
			position: relative;
			color: $color-white;
			z-index: 90;

			@include media('<md') {
				align-self: flex-end;
				width: 90%;
				transform: translateY(-30px);
				order: 1;
			}

			@include media('>=md') {
				max-width: 700px;
				width: 100%;
			}
		}

		&--w-img {
			@include media('<md') {
				order: 0;
			}
		}

		&--color-teal {
			background: $color-dark-teal;
		}

		&--color-purple {
			background: $color-dark-purple;
		}

		&--reversed {
			@include media('<md') {
				order: 1;
			}
		}

		&--w-video {
			display: flex;
			align-items: center;
			padding-bottom: 0;

			.video {
				padding: 0;

				&__wrapper {
					margin: 0;
				}
			}

			video {
				//@include container();
				height: 100%;
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				object-fit: cover;
			}
		}

		&.animate-right-text {
			@include media('<=md') {
				align-self: flex-start;
			}
		}
	}

	&__content {
		&-title {
			@include responsify($font-sizes-lg);
			@include responsify($line-height-sm, line-height);
			margin-bottom: 0.3em;
			font-weight: $font-weight-bold;
		}

		&-text {
			@include responsify($spacing-md, padding-top);
			@include responsify($font-sizes-nm);
			@include responsify($line-height-lg, line-height);
			@include reverseListReset();
			font-weight: $font-weight-normal;
			color: currentColor;

			p {
				&:last-of-type {
					margin-bottom: 0;
				}
			}
		}
	}

	&__image {
		width: 100%;
		height: 100%;
		line-height: 0;

		@include media('>=md') {
			max-width: 960px;
			max-height: 554px;
			margin-left: auto;
		}

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			box-shadow: 0 0 18px 7px rgba(0, 0, 0, 0.2);
		}
	}
}
