// wrapper-config
$body-margin: 0; // setup margin body should have here

// responsive breakpoints
$breakpoints: (
  'xxs': 20em, // 320px
  'xs': 30em, // 480px
  'sm': 48em, // 768px
  'ms': 62em, // 992px
  'md': 64em, // 1024px
  'lg': 80em, // 1280px
  'xl': 90em, // 1440px
  //'xxl': 120em, // 1920px
);

// creen in with large-images should load instead of small ones
$breakpoint-largeimage: "md";

$side-margins: 1rem, 1rem, 2rem, 2rem, 2rem, 3rem; // this should replace site-margins and calculated margins

// alternative for wrapper sizes --> should replace wrapper mixin
$container-sizes: 'slim', 'narrow', 'narrow-plus', 'wider', 'normal', 'fluid';
$container-max-widths: 35rem, 50rem, 68rem, 84rem, 105rem, 112rem;

// system spacings - spacings are lists of different spacing sizes used for different breakpoints to add configurable spacing to any object you want
// define spacing for every breakpoint you want; spacings are matched with the screens list
$spacing-no: 0rem, 0rem, 0rem; // needed to responsively override zero margins - DO NOT REMOVE OR CHANGE!
$spacing-xxs: 0.3125rem, 0.3125rem, 0.4375rem;
$spacing-xs: 0.625rem, 0.625rem, 0.875rem;
$spacing-sm: 0.3125rem, 0.625rem, 1.25rem;
$spacing-nm: 1rem, 1.5625rem, 1.5625rem;
$spacing-md: 0.625rem, 0.9375rem, 1.875rem;
$spacing-lg: 1.25rem, 1.5625rem, 2.5rem;
$spacing-xl: 1.875rem, 2.5rem, 3.75rem;
$spacing-xxl: 2.5rem, 2.5rem, 2.8rem, 3rem, 3rem, 3.5rem, 4.75rem;
$spacing-xxxl: 5rem, 5rem, 5.6rem, 6rem, 6rem, 7rem, 9.5rem;

// custom spacings
$spacing-module: 2rem, 2rem, 4rem, 5rem, 5rem, 6rem;
$spacing-col-row-grid-xs: 0.5rem, 0.5rem, 0.75rem, 0.75rem, 0.75rem, 1rem;
$spacing-col-row-grid: 0.5rem, 1rem, 1rem, 1.25rem, 1.25rem, 1.875rem;
$spacing-input-group: 0.25rem;
$spacing-input-group-half: ($spacing-input-group / 2);

// CSS Grid configuration
$grid-max-cols: 5; // how many element classes (e.g. "element-cols-rows") should be generated by the framework

// Ratios for media sizes
$ratio-21-9: (100% / 21 * 9);
$ratio-16-9: (100% / 16 * 9);
$ratio-16-10: (100% / 16 * 10);
$ratio-4-3: (100% / 4 * 3);
$ratio-3-4: (100% / 3 * 4);
$ratio-3-2: (100% / 3 * 2);
$ratio-1-1: 100%;
$ratio-portrait: $ratio-3-4;
$ratio-landscape: $ratio-16-9;
$ratio-square: $ratio-1-1;
