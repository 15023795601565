// basic colors (non element related)
// define as many as you want...
// PLEASE DO NOT DELETE EXISTING COLORS HERE! THEY ARE NEEDED WITHIN THE system
$color-red: red;
$color-grey: #CFCFCF; // used for input backgrounds, etc.
$color-grey-light: #E9E9E9; // used for input backgrounds, etc.
$color-grey-ultralight: #f9f9f9;
$color-main: #000000; // main highlight color of the system
$color-white: #ffffff;
$color-black: #000000;
$color-yellow: #00ff00;
$color-guave: #a4c92c;
$color-guave-hover: #b3df25;
$color-primary: $color-black;
$color-dark-teal: #557873;
$color-dark-purple: #605971;
