.nav-wrapper {
	@include media('<#{$desktop-breakpoint}') {
		@include container();
		@include responsify($header-mobile-height, top);
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		display: block;
		flex: 0;
		height: 100vh;

		&:after {
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			bottom: 0;
			width: 0;
			height: 0;
			border-top: solid 90vh $color-dark-teal;
			border-right: solid 93vw transparent;
			z-index: 98;
		}
	}

	@include media('>=#{$desktop-breakpoint}') {
		display: block;
		flex: 1;
		transform: translateX(0);
	}

	&.expanded {
		@include media('<#{$desktop-breakpoint}') {
			animation: expandNavi 0.3s $ease-in-out forwards;
		}
	}

	&:not(.expanded) {
		@include media('<#{$desktop-breakpoint}') {
			animation: collapseNavi 0.3s $ease-in-out forwards;
		}
	}

	.mod_navigation {
		position: relative;
		z-index: 99;

		.level_1 {
			@include responsify($spacing-lg, padding-top);

			@include media('>=#{$desktop-breakpoint}') {
				@include responsify($spacing-no, padding-top);
				@include responsify($font-size-nav-items);
				display: flex;
				flex-flow: row;
				justify-content: flex-start;
				align-items: center;
				margin-bottom: 0;
			}

			.nav-item {
				margin-bottom: 2em;
				margin-right: 1em;

				@include media('>=#{$desktop-breakpoint}') {
					margin-bottom: 0;
				}

				@include media('>=xl') {
					margin-right: 2em;
				}

				&:last-of-type {
					margin-right: 0;
				}

				&__link {
					@include media('<#{$desktop-breakpoint}') {
						color: $color-white;
					}

					&.active {
						font-weight: $font-weight-bold;
					}

					&--last {
						font-size: 1.125rem;
					}
				}

				&--last {
					margin-left: auto;
				}
			}
		}
	}
}

.mobile-navi {
	display: flex;
	flex-flow: column;
	align-items: center;

	@include media('>=#{$desktop-breakpoint}') {
		display: none;
	}
}

body {
	&:after {
		content: '';
		position: absolute;
		inset: 0;
		display: block;
		background: rgba(128, 128, 128, 0.66);
		opacity: 0;
		transition:opacity 0.4s $ease-in-out, transform 0.3s $ease-in-out;
		transform: translateX(100%);
		z-index: 97;
	}

	&.show-shadow {
		position: relative;

		&:after {
			opacity: 1;
			transition: opacity 0s $ease-in-out, transform 0.3s $ease-in-out;
			transform: translateX(0);
		}
	}
}
