// http://meyerweb.com/eric/tools/css/reset/
//  v2.0 | 20110126
//  License: none (public domain)

a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
ruby,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
// HTML5 display-role reset for older browsers

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

html {
  overflow-x: hidden;
	max-width: 100vw;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:after,
blockquote:before,
q:after,
q:before {
  content: '';
  content: none;
}

a,
button {
  text-decoration: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input,
textarea {
  box-sizing: border-box;
  -webkit-appearance: none;
  appearance: none;
  border: none;
  line-height: 1;
}

*:focus {
  outline: none;
}

form {
  overflow: hidden;
}

::-moz-selection { background: rgba(0,0,0,1); color: white;}
::selection { background: rgba(0,0,0,1); color: white;}

// Remove default arrow from drop-down
select {
	-moz-appearance: none;
	-webkit-appearance: none;
}

select::-ms-expand {
	display: none;
}

// reset placeholders
::-webkit-input-placeholder {
	/* WebKit browsers */
	color: transparent;
	opacity: 0;
}

:-moz-placeholder {
	/* Mozilla Firefox 4 to 18 */
	color: transparent;
	opacity: 0;
}

::-moz-placeholder {
	/* Mozilla Firefox 19+ */
	color: transparent;
	opacity: 0;
}

:-ms-input-placeholder {
	/* Internet Explorer 10+ */
	// don't use opacity, because it also disables the background color of the input
	// and use !important because the color would be overridden by the color of the input
	color: transparent !important;
	// opacity: 0;
}
