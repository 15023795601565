.header {
	@include responsify($spacing-lg, padding-top);
	@include responsify($spacing-lg, padding-bottom);
	@include responsify($header-mobile-height, height);
	position: sticky;
	top: 0;
	left: 0;
	right: 0;
	display: flex;
	align-items: center;
	z-index: 100;
	background: $color-white;
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);

	@include media('>=#{$desktop-breakpoint}') {
		@include responsify($spacing-sm, padding-top);
		@include responsify($spacing-sm, padding-bottom);
		height: 100%;
	}

	.container {
		display: flex;
		flex-flow: row;
		align-items: center;
		justify-content: space-between;

		.logo {
			@include responsify($font-sizes-lg-plus);
			@include responsify($spacing-xxl, margin-right);
			flex: 0 0 10%;
			font-weight: $font-weight-semi-bold;
		}

		.meta-nav {
			flex: 0 0 7%;
			display: flex;
			align-items: center;
			justify-content: flex-end;
			align-self: center;
			justify-self: flex-end;
		}
	}
}
