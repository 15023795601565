@keyframes expandNavi {
	0% {
		transform: translateX(-100vw);
		visibility: hidden;
	}

	100% {
		transform: translateX(0);
		visibility: visible;
	}
}

@keyframes collapseNavi {
	0% {
		transform: translateX(0);
		visibility: visible;
	}
	100% {
		transform: translateX(-100vw);
		visibility: hidden;
	}
}
