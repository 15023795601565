.column-layout {
	display: flex;
	flex-flow: column;
	align-items: stretch;

	@include media('>=md') {
		flex-flow: row;
	}

	.left-column {
		display: contents;
		flex-flow: column;
		background: $color-dark-purple;

		@include media('>=#{$desktop-breakpoint}') {
			display: flex;
			flex-flow: row;
			flex: 0 0 35%;
		}

		&__content {
			@include responsify($spacing-lg, padding);
			display: contents;
			width: 100%;
			align-self: stretch;
			color: $color-white;

			@include media('>=md') {
				display: block;
			}
		}

		&__wrapper {
			display: contents;

			@include media('>=md') {
				position: sticky;
				top: calc(#{$header-height} + 6rem);
				display: block;
			}
		}

		&__intro {
			@include responsify($font-sizes-lg);
			font-weight: $font-weight-bold;
			line-height: 1.15;

			@include media('<md') {
				@include responsify($spacing-lg, padding);
				background: $color-dark-purple;
				color: $color-white;
			}
		}

		&__container	{
			@include media('<md') {
				@include responsify($header-mobile-height, top);
				position: sticky;
				left: 0;
				right: 0;
				background: $color-dark-purple;
				z-index: 97;
				box-shadow: 0 8px 10px 0 rgba(0, 0, 0, 0.2);
			}
		}

		&__index {
			@include responsify($spacing-lg, padding);
			@include responsify($spacing-nm, padding-left);
			max-width: 100%;
			width: 100%;
			padding-top: 2rem;

			@include media('<md') {
				display: flex;
				flex-flow: row;
				overflow-x: auto;
				margin-bottom: 0;
				color: $color-white;
				flex: 1 1 auto;
			}

			@include media('>=md') {
				display: flex;
				flex-flow: column;
			}

			li {
				@include media('<md') {
					flex: 0 0 auto;
					padding-bottom: 0.8rem;
					padding-right: 0.4em;
					padding-left: 0.4em;
				}

				@include media('>=md') {
					margin: 0.5rem 0;
				}

				&:last-of-type {
					@include media('<md') {
						@include responsify($spacing-lg, padding-right);
					}
				}

				.index-link {
					color: $color-white;

					@include media('<md') {
						padding-bottom: 0.4rem;
					}

					&.active {
						position: relative;
						font-weight: $font-weight-semi-bold;

						&:after {
							content: '';
							position: absolute;
							bottom: 0;
							left: 0;
							right: 0;
							width: 100%;
							height: 3px;
							background: $color-white;
						}

						@include media('>=md') {
							&:after {
								display: none;
							}

							&:before {
								content: '';
								position: absolute;
								top: 50%;
								bottom: 0;
								left: -20px;
								display: block;
								width: 10px;
								height: 10px;
								transform: translateY(-50%);
								background: $color-white;
								border-radius: 100px;
							}
						}
					}
				}
			}

			&--mobile {
				@include media('<md') {
					position: sticky;
					top: 48px;
					left: 0;
					right: 0;
					display: flex;
					flex-flow: row;
					overflow-x: auto;
					margin-bottom: 0;
					background: $color-dark-purple;
					z-index: 100;
				}

				@include media('>=md') {
					display: none;
					opacity: 0;
					visibility: hidden;
				}

				.navigation-open & {
					display: none;
				}
			}
		}
	}

	.right-column {
		background: $color-grey-ultralight;

		@include media('>=md') {
			flex: 0 0 65%;
		}

		&__content {
			@include responsify($spacing-xl, padding-top);
			@include responsify($spacing-lg, padding-bottom);
			@include responsify($spacing-lg, padding-left);
			@include responsify($spacing-lg, padding-right);
			color: $color-black;

			@include media('>=md') {
				padding: 6rem;
			}
		}

		&__section {
			@include responsify($spacing-lg, margin-bottom);
			//min-height: 50vh;

			@include media('>=md') {
				//min-height: 0;
				//height: 80vh;
			}
		}

		&__intro {
			@include fluidsize($font-sizes-md-plus);
			margin-bottom: 1rem;
			font-weight: $font-weight-bold;
		}

		&__details {
		}

		&__graphic {
			@include responsify($spacing-lg, margin-top);
			max-height: 60vh;
			height: 100%;
			overflow: hidden;
			text-align: center;
			aspect-ratio: 16/9;

			img {
				width: 100%;
				//height: 100%;
				object-fit: cover;
			}
		}
	}
}
