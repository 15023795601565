.section {
	position: relative;

	&--skewed {
		//margin-bottom: 10%;
		overflow: visible;

		&:before,
		&:after {
			content: '';
			position: absolute;
			left: 0;
			right: 0;
			width: 0;
			height: 0;
			margin: 0 auto;
			z-index: 90;
		}

		&:before {
			content: '';
			top: -30px;
			border-bottom: solid 30px transparent;
			border-left: solid 100vw transparent;

			@include media('>=ms') {
				top: -60px;
				border-bottom: solid 60px transparent;
			}

			@include media('>=lg') {
				top: -100px;
				border-bottom: solid 100px transparent;
			}
		}

		&:after {
			content: '';
			bottom: -30px;
			border-top: solid 30px transparent;
			border-right: solid 100vw transparent;

			@include media('>=ms') {
				bottom: -60px;
				border-top: solid 60px transparent;
			}

			@include media('>=lg') {
				bottom: -100px;
				border-top: solid 100px transparent;
			}
		}
	}

	&--dark-teal {
		background: $color-dark-teal;

		&:before {
			border-bottom-color: $color-dark-teal;
		}

		&:after {
			border-top-color: $color-dark-teal;
		}
	}

	&--dark-purple {
		background: $color-dark-purple;

		&:before {
			border-bottom-color: $color-dark-purple;
		}

		&:after {
			border-top-color: $color-dark-purple;
		}
	}

	&--white {
		background: $color-white;
	}

	&--grey {
		background: $color-grey;
	}

	&--static {
		position: static;
	}

	&--btm-skew {
		&:after {
			content: '';
			position: sticky;
			left: 0;
			right: 0;
			width: 100vw;
			height: 0;
			margin: 0 auto;
			z-index: 98;
			bottom: -10rem;
			border-top: solid 60px transparent;
			border-right-width: 100vw;
			border-right-style: solid;
			border-right-color: $color-white;

			@include media('>=md') {
				border-top: solid 100px transparent;
			}
		}

		-ms-overflow-style: none;
		scrollbar-width: none;

		&::-webkit-scrollbar {
			display: none;
		}
	}

	@for $h from 10 through 200 {
		&--#{$h}vh {
			min-height: calc((#{$h} / 2) * 1vh);

			@include media('>=lg') {
				min-height: #{$h}vh;
			}
		}
	}

	&--hero {
		@include media('<md') {
			min-height: $section-min-mobile-height;

		}
	}

	> .ce_form {
		margin-top: 0;
	}
}


