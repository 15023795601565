select {
	@include responsify($spacing-xxs, padding);
	width: 100%;
	border: 2px solid $select-border-color;
	color: $select-border-color;
	background: url('#{$icons-base-path}chevron-down-select.svg') no-repeat right 10px center;
	background-size: 0.8rem;

	@include media('>=lg') {
		background-size: 1.25rem;
	}
}

.textarea {
	$textarea: &;

	&__container {
		position: relative;
		width: 100%;
		line-height: 0;
	}

	&__label {
		@include responsify($font-sizes-nm);
		position: absolute;
		top: auto;
		bottom: 16px;
		left: 0.625rem;
		padding: 1.125rem 0.75rem 0;
		color: $color-dark-purple;
		pointer-events: none;
		font-weight: $font-weight-normal;
	}

	&__textarea {
		@include responsify($spacing-md, 'margin-bottom');
		@include responsify($font-sizes-sm);
		width: 100%;
		padding: (2 * $field-padding) $field-padding $field-padding; // use var
		resize: none;
		background: transparent;
		color: $color-dark-purple;

		&:focus,
		&:not(:placeholder-shown) {
			& ~ #{$textarea}__label {
				top: 1rem;
				font-size: 0.95em;
				transition: font-size 0.2s $ease-out-quad, top 0.2s $ease-out-quad;

				@include media('<md') {
					font-size: 0;
				}
			}
		}
	}
}

