$body-bg: $color-white;
$text-color: $color-black;
$text-color-highlight: $color-primary;

$link-color: $color-black;
$link-decoration: underlined;
$link-hover-color: lighten($color-black, 15%);
$link-hover-decoration: none;

// buttons configurable variables
$button-font-weight: $font-weight-bold;
$button-transition: 0.3s $ease-out-quad;
$button-padding-vertical: 2em;
$button-padding-horizontal: 0.8em;
$button-font-size: $font-sizes-sm;
$button-border: none;
$button-border-radius: 0;
$button-box-shadow: none;
$button-small-padding-vertical: 0.5em;
$button-small-padding-horizontal: 1em;
$button-small-font-size: $font-sizes-xs;
$button-small-border: 0;
$button-small-border-radius: 0;
$button-small-box-shadow: none;

$button-color: $color-white;
$button-primary-bg: $color-dark-teal;
$button-primary-bg-hover: darken($button-primary-bg, 5%);
$button-primary-color: $color-white;
$button-primary-color-hover: $color-white;
$button-secondary-bg: $color-guave;
$button-secondary-bg-hover: darken($button-secondary-bg, 5%);
$button-secondary-color: $color-white;
$button-secondary-color-hover: $color-white;

// form fields configuragtion
$field-maxwidth: 31.25rem; // fields will not get bigger than this, please do not use none, use a value here
$field-minwidth: 12.5rem; // fields will not get smaller than this, please do not use none, use a value here
$field-padding: 0.75rem; // standard padding of fields, non responsive, but you can use a spacing var here (single value, no list)
$field-spacing: 0.375rem; // standard spacing between label and text, non responsive but you can use a spacing var here (single value, no list)
$field-linecolorvalid: $color-black;
$field-linecolorinvalid: $color-red;
$field-error: $color-red;
$field-error-font-style: bold;
$field-success: $color-guave;
$field-success-font-style: bold;
$field-containerbgcolor: transparent;
$field-containerbg-hover: $color-grey-ultralight;
$field-color: $color-black;
$field-color-active: $color-black;
$field-label-color: rgba($color-black, 0.4);
$field-label-color-active: rgba($color-black, 0.6);
$field-fontsize: 1rem, 1rem, 1.25rem, 1.25rem, 1.25rem;
$field-line-height: 1.2;
$field-border-radius: 0;
$field-additional-color: rgba($text-color, 0.4);

$range-input-track-color-left: $color-guave;
$range-input-track-color-right: $color-grey-light;
$range-input-thumb-color: $color-guave;

// custom variables
$desktop-breakpoint: 64em;
$header-mobile-height: 72px, 72px, 80px, 80px, 80px;
$header-height: 103px;
$font-size-nav-items: 1rem, 1.1rem, 1.2rem, 1.2rem, 1.125rem, 1.25rem, 1.375rem; //max - 22px

$select-border-color: $color-dark-purple;
$section-min-mobile-height: 80vh;


